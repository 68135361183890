import React, { Component, lazy, Suspense } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./Styles/main.scss";
import "./App.css";
const TRACKING_ID = "UA-186813100-2";

ReactGA.initialize(TRACKING_ID);

const Home = lazy(() => import("./components/pages/Home.jsx"));
const Kim = lazy(() => import("./components/pages/Kim.jsx"));
const Projecten = lazy(() => import("./components/pages/Projecten.jsx"));
const Project1 = lazy(() =>
  import("./components/pages/Projecten/how-smart-is-your-skin.jsx")
);
const Project2 = lazy(() =>
  import("./components/pages/Projecten/de-beleving.jsx")
);
const Project3 = lazy(() =>
  import("./components/pages/Projecten/out-of-context.jsx")
);
const Project4 = lazy(() =>
  import("./components/pages/Projecten/data-visualisatie-philips.jsx")
);
const Project5 = lazy(() =>
  import("./components/pages/Projecten/sorted-by-characters.jsx")
);
const Contact = lazy(() => import("./components/pages/Contact.jsx"));
const Posters = lazy(() => import("./components/pages/Posters.jsx"));

function App() {
  const tabNotActive = (event) => {
    document.title = document.hidden ? "Mis het feestje niet!" : "Whoop whoop!";
  };
  React.useEffect(() => {
    window.addEventListener("visibilitychange", tabNotActive);

    // cleanup this component
    return () => {
      window.removeEventListener("visibilitychange", tabNotActive);
    };
  }, []);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Suspense fallback="Loading...">
      <div>
        <Router>
          <React.Fragment>
            <Router>
              <div>
                <Route exact={true} path="/" render={() => <Home />} />
                <Route exact={true} path="/kim" render={() => <Kim />} />
                <Route
                  exact={true}
                  path="/projecten"
                  render={() => <Projecten />}
                />
                <Route
                  exact={true}
                  path="/how-smart-is-your-skin"
                  render={() => <Project1 />}
                />
                <Route
                  exact={true}
                  path="/de-beleving"
                  render={() => <Project2 />}
                />
                <Route
                  exact={true}
                  path="/out-of-context"
                  render={() => <Project3 />}
                />
                <Route
                  exact={true}
                  path="/data-visualisatie-philips"
                  render={() => <Project4 />}
                />
                <Route
                  exact={true}
                  path="/sorted-by-characters"
                  render={() => <Project5 />}
                />
                <Route
                  exact={true}
                  path="/Contact"
                  render={() => <Contact />}
                />
                <Route
                  exact={true}
                  path="/Posters"
                  render={() => <Posters />}
                />
              </div>
            </Router>
          </React.Fragment>
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
